.PostContainer {
  border: 2px solid #D5D5D5;
  width: 498px;
  border-radius: 7px;
  margin-bottom: 12px;
}
.Posttitle {
  color: #343434;
  font-weight: 600;
  line-height: 18px;
}
.PostLocation {
  line-height: 18px;
  color: #B3B3B3;
}
.PostWrapper {
  width: calc(100% - 80px);
  padding: 10px;
}
.PostAction {
  width: 59px;
  line-height: 30px;
  font-weight: 600;
  color: #343434;
  vertical-align: top;
  padding-bottom: 28px;
  font-size: 28px;
  cursor: pointer;
}
.PostHeader>div {
  display: inline-block;
  line-height: 48px;
}
.PostImageWrapper img {
  max-height: 240px;
  display: block;
  width: 498px;
}
.PostImageWrapper {
  background: white;
  text-align: center;
}
.Postview {
  margin-left: 30%;
}
.righthandside {
  float: right;
  margin-right: 97px;
  transform: rotate(0deg) translateY(15px);
  border: none;
}
.lefthandside {
  float: left;
  color: rgb(15 157 88);
  font-size: 50px;
  margin-bottom: 12px;
}
.Likescontainer button {
  background: #fff;
  border: none;
  outline: none;
  overflow: hidden;
  cursor: pointer;
}
.ShareButton {
  transform: rotate(325deg) translateY(-2px);
}
.header-Container {
  border: 2px solid #D5D5D5;
  height: 60px;
  margin-bottom: 10px;
  width: 800px;
  margin-left: 250px;
}
.PostedOn {
  text-align: right;
  color: #8A8A8A;
}
.PostDetails>div {
  display: inline-block;
  padding: 10px;
  vertical-align: top;
  width: calc(50% - 20px);
}
.Likes {
  color: #8A8A8A;
  padding-left: 10px;
  font-size: 15px;
}
.PostCaption {
  padding-left: 13px;
}